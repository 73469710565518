import { AfterViewInit, ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GetProcessedDeliveriesByDateRangeService } from '../../graphql/dashboard-operations.generated';
import { SetDashboardTableRangeDates } from '../../state/ui.actions';
import { DashboardPageViewModel, DashboardPageViewModelQueries } from '../../view-models/dashboard-view-model.queries';
import * as dayjs from 'dayjs';

@Component({
  selector: 'prf-dashboard-view',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    CardModule,
    TooltipModule,
    CalendarModule,
    FormsModule,
  ],
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardViewComponent implements AfterViewInit {
  private getProcessedDeliveriesByDateRangeService = inject(GetProcessedDeliveriesByDateRangeService);
  private store = inject(Store);
  messages = signal<any[]>([]);
  loadingMessages = signal(false);

  @Select(DashboardPageViewModelQueries.getViewModel)
  viewModel$!: Observable<DashboardPageViewModel>;

  ngAfterViewInit(): void {
    this.loadMessagesByDateRange();
  }

  refreshMessages(): void {
    this.loadMessagesByDateRange();
  }

  onTableRangeDatesSelect($event: Date[] | undefined) {
    console.log('Date range selected:', $event);
    this.store.dispatch(new SetDashboardTableRangeDates({ rangeDates: $event }));

    if ($event?.length === 2) {
      this.loadMessagesByDateRange();
    } else {
      this.messages.set([]);
    }
  }

  private loadMessagesByDateRange(): void {
    const rangeDates = this.store.selectSnapshot(state => state.ui.dashboardUi.tableRangeDates);

    if (!rangeDates?.length || !rangeDates[0] || !rangeDates[1]) {
      this.messages.set([]);
      return;
    }

    const [fromDate, toDate] = rangeDates;
    const endDate = dayjs(toDate).endOf('day').toDate();

    this.loadingMessages.set(true);
    this.getProcessedDeliveriesByDateRangeService.fetch({
      fromDate,
      toDate: endDate,
      excludeEmptyMessages: true,
    }).subscribe({
      next: (result) => {
        this.messages.set(result.data?.processedDeliveriesByDateRange ?? []);
        this.loadingMessages.set(false);
      },
      error: () => {
        this.messages.set([]);
        this.loadingMessages.set(false);
      }
    });
  }
}

//// DASHBOARD EXAMPLE - TOP COLORED CARDS --- ie todays deliveries...
// ----------> https://avalon.primeng.org/
// ----------> https://ultima.primeng.org/
