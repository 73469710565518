import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MarketMessageFieldsFragment = { __typename?: 'ProcessedDeliveryEntity', id: number, messageFromMarket: string, deliveredAtMarketDate: any, signatureName: string, delivery: { __typename?: 'DeliveryEntity', deliverySlipNumber: string, driver?: { __typename?: 'UserEntity', firstName: string, lastName: string } | null, market?: { __typename?: 'MarketEntity', marketName: string, customerNumber: number } | null } };

export type GetLatestProcessedDeliveriesQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  excludeEmptyMessages?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetLatestProcessedDeliveriesQuery = { __typename?: 'Query', processedDeliveries: Array<{ __typename?: 'ProcessedDeliveryEntity', id: number, messageFromMarket: string, deliveredAtMarketDate: any, signatureName: string, delivery: { __typename?: 'DeliveryEntity', deliverySlipNumber: string, driver?: { __typename?: 'UserEntity', firstName: string, lastName: string } | null, market?: { __typename?: 'MarketEntity', marketName: string, customerNumber: number } | null } }> };

export type GetProcessedDeliveriesByDateRangeQueryVariables = Types.Exact<{
  fromDate: Types.Scalars['DateTime']['input'];
  toDate: Types.Scalars['DateTime']['input'];
  excludeEmptyMessages?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetProcessedDeliveriesByDateRangeQuery = { __typename?: 'Query', processedDeliveriesByDateRange: Array<{ __typename?: 'ProcessedDeliveryEntity', id: number, messageFromMarket: string, deliveredAtMarketDate: any, signatureName: string, delivery: { __typename?: 'DeliveryEntity', deliverySlipNumber: string, driver?: { __typename?: 'UserEntity', firstName: string, lastName: string } | null, market?: { __typename?: 'MarketEntity', marketName: string, customerNumber: number } | null } }> };

export const MarketMessageFieldsFragmentDoc = gql`
    fragment MarketMessageFields on ProcessedDeliveryEntity {
  id
  messageFromMarket
  deliveredAtMarketDate
  signatureName
  delivery {
    deliverySlipNumber
    driver {
      firstName
      lastName
    }
    market {
      marketName
      customerNumber
    }
  }
}
    `;
export const GetLatestProcessedDeliveriesDocument = gql`
    query GetLatestProcessedDeliveries($limit: Int, $excludeEmptyMessages: Boolean) {
  processedDeliveries(limit: $limit, excludeEmptyMessages: $excludeEmptyMessages) {
    ...MarketMessageFields
  }
}
    ${MarketMessageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLatestProcessedDeliveriesService extends Apollo.Query<GetLatestProcessedDeliveriesQuery, GetLatestProcessedDeliveriesQueryVariables> {
    override document = GetLatestProcessedDeliveriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProcessedDeliveriesByDateRangeDocument = gql`
    query GetProcessedDeliveriesByDateRange($fromDate: DateTime!, $toDate: DateTime!, $excludeEmptyMessages: Boolean) {
  processedDeliveriesByDateRange(
    fromDate: $fromDate
    toDate: $toDate
    excludeEmptyMessages: $excludeEmptyMessages
  ) {
    ...MarketMessageFields
  }
}
    ${MarketMessageFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProcessedDeliveriesByDateRangeService extends Apollo.Query<GetProcessedDeliveriesByDateRangeQuery, GetProcessedDeliveriesByDateRangeQueryVariables> {
    override document = GetProcessedDeliveriesByDateRangeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }