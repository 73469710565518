@if (viewModel$ | async; as viewModel) {
  <p-card>
    <ng-template pTemplate="header">
      <div class="flex justify-content-between align-items-center px-3 py-2">
        <div class="flex align-items-center gap-4">
          <span class="text-xl font-medium">Aktuelle Markt-Nachrichten</span>
          <p-calendar
            [ngModel]="viewModel.tableRangeDates"
            (ngModelChange)="onTableRangeDatesSelect($event)"
            selectionMode="range"
            placeholder="Zeitraum wählen"
            [showIcon]="true"
            [showClear]="true"
            [showButtonBar]="true"
            [readonlyInput]="true"
            [numberOfMonths]="2"
            [selectOtherMonths]="true"
            [showWeek]="true">
          </p-calendar>
        </div>
        <p-button
          icon="pi pi-refresh"
          (onClick)="refreshMessages()"
          [rounded]="true"
          severity="secondary"
          [text]="true"
          pTooltip="Nachrichten aktualisieren"
          class="p-0">
        </p-button>
      </div>
    </ng-template>

    <p-table
      [value]="messages()"
      [tableStyle]="{ 'min-width': '40rem' }"
      [loading]="loadingMessages()"
      styleClass="p-datatable-sm p-datatable-gridlines"
      [scrollable]="true"
      scrollHeight="450px">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 180px">Datum</th>
          <th style="width: 160px">Lieferschein</th>
          <th style="width: 300px">Markt</th>
          <th style="width: 200px">Fahrer</th>
          <th style="width: 200px">Markt-Mitarbeiter</th>
          <th>Nachricht vom Markt</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-message>
        <tr>
          <td style="width: 180px">{{ message.deliveredAtMarketDate | date:'dd.MM.yy, HH:mm' }} Uhr</td>
          <td style="width: 160px">{{ message.delivery.deliverySlipNumber }}</td>
          <td style="width: 300px">{{ message.delivery.market?.marketName }}</td>
          <td style="width: 200px">{{ message.delivery.driver?.firstName }} {{ message.delivery.driver?.lastName }}</td>
          <td style="width: 200px">{{ message.signatureName }}</td>
          <td class="message-cell">{{ message.messageFromMarket }}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6" class="text-center p-2">
            <i>
              @if (loadingMessages()) {
                <p>
                  Nachrichten werden geladen...
                </p>
              } @else if (!viewModel.tableRangeDates?.length) {
                <p>
                  Bitte wählen Sie einen Zeitraum aus.
                </p>
              } @else {
                <p>Für den gewählten Zeitraum sind keine Nachrichten vorhanden.</p>
              }
            </i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
}
