import { Selector } from '@ngxs/store';
import { DashboardUiState, UiState } from '../state/ui.state';

export interface DashboardPageViewModel {
  tableRangeDates: Date[] | undefined;
}

export class DashboardPageViewModelQueries {
  @Selector([UiState.dashboardState])
  static getViewModel(
    dashboardUiState: DashboardUiState,
  ): DashboardPageViewModel {
    return {
      tableRangeDates: dashboardUiState.tableRangeDates,
    };
  }
}
